import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { up, down } from 'styled-breakpoints';

import useBreakpoint from '@babelcoder/gatsby-theme-base/src/hooks/useBreakpoint';

const CourseLink = styled(Link)`
  ${({ theme }) => css`
    box-shadow: 0px 0px 5px 0px ${theme.colors.neutral.gray400};
    padding: 1rem;

    border-radius: ${theme.round.xlarge};
    background-color: ${theme.colors.neutral.white};
  `}
`;

const Container = styled.article`
  ${up('medium')} {
    display: grid;
    grid-template:
      'image' auto
      'title' auto
      'excerpt' auto
      'spacer' 1fr
      'footer' auto / auto;
    height: 100%;

    ${up('xlarge')} {
      grid-template:
        'image title' auto
        'image excerpt' auto
        'spacer spacer' 1fr
        'footer footer' auto / auto 1fr;
    }
  }
`;

const Spacer = styled.div`
  ${({ theme }) => css`
    grid-area: spacer;
    margin: ${theme.spacers.normal} 0;
  `}
`;

const CourseImageWrapper = styled(Img)`
  ${({ theme }) => css`
    grid-area: image;

    ${up('xlarge')} {
      margin-right: ${theme.spacers.normal};
    }
  `}
`;

const StyledCourseImage = styled(Img)`
  width: 100%;
  box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.colors.neutral.gray300};
`;

const Title = styled.h2`
  ${({ theme }) => css`
    grid-area: title;
    margin: 0;
    font-size: ${theme.fontSizes.normal};
    color: ${theme.colors.neutral.gray1200};

    ${down('large')} {
      font-size: ${theme.fontSizes.medium};
      margin: ${theme.spacers.normal} 0;
    }
  `}
`;

const Excerpt = styled.p`
  ${({ theme }) => css`
    grid-area: excerpt;
    margin: 0;
    color: ${theme.colors.neutral.gray1100};
    font-size: ${theme.fontSizes.small};

    ${down('large')} {
      font-size: ${theme.fontSizes.normal};
    }
  `}
`;

const CourseFooter = styled.footer`
  ${({ theme }) => css`
    grid-area: footer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${theme.spacers.normal};
    border-top: 1px solid ${theme.colors.neutral.gray200};
  `}
`;

const Author = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorImage = styled(Img)`
  border-radius: 1rem;
  box-shadow: 0px 0px 8px 0px ${({ theme }) => theme.colors.neutral.gray600};
`;

const AuthorName = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray800};
    margin-left: ${theme.spacers.normal};
    font-size: ${theme.fontSizes.small};
  `}
`;

function CourseImage({ desktopImage, mobileImage }) {
  const size = useBreakpoint();
  const isFixed = size === 'xlarge';
  const props = isFixed
    ? { fixed: desktopImage.childImageSharp.fixed }
    : { fluid: mobileImage.childImageSharp.fluid };

  return (
    <CourseImageWrapper as={isFixed ? 'div' : 'span'}>
      <StyledCourseImage {...props}></StyledCourseImage>
    </CourseImageWrapper>
  );
}

function CourseItem({
  creatorImage,
  course: { slug, title, excerpt, desktopImage, mobileImage },
}) {
  return (
    <CourseLink to={`/courses/${slug}/`}>
      <Container>
        <CourseImage
          desktopImage={desktopImage}
          mobileImage={mobileImage}
        ></CourseImage>
        <Title>{title}</Title>
        <Excerpt>{excerpt}</Excerpt>
        <Spacer></Spacer>
        <CourseFooter>
          <Author>
            <AuthorImage fixed={creatorImage.fixed}></AuthorImage>
            <AuthorName>Nuttavut Thongjor</AuthorName>
          </Author>
        </CourseFooter>
      </Container>
    </CourseLink>
  );
}

export default CourseItem;
