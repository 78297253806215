import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { FaVideo, FaUsers, FaGlobe } from 'react-icons/fa';

import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash';
import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta';
import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO';
import Container from '@babelcoder/site/src/components/home/Container';
import SectionHeader from '@babelcoder/site/src/components/home/SectionHeader';
import OnlineCourseItem from './online/CourseItem';
import ClassroomCourseItem from './classroom/CourseItem';
import EnterpriseCourseItem from './enterprise/CourseItem';
import PageTitle from '@babelcoder/gatsby-theme-base/src/components/PageTitle';

const CourseList = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: ${({ theme }) => theme.spacers.normal};

  ${up('xlarge')} {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }
`;

const Section = styled.section`
  margin-top: 1rem;
`;

const Courses = ({ onlineCourses, classroomCourses, enterpriseCourses }) => {
  const { siteName, siteUrl } = useSiteMeta();
  const title = `คอร์สทั้งหมดของ ${siteName}`;
  const desc = `คอร์สสอนการโปรแกรมภาษาต่าง ๆ และแนวคิดที่เกี่ยวข้องของ ${siteName}`;
  const path = '/courses';
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: title,
    description: desc,
    url: toSingleSlash(`${siteUrl}/${path}`),
    sameAs: 'https://facebook.com/babelcoder',
    inLanguage: 'th-TH',
  };
  const { creatorImage } = useStaticQuery(graphql`
    query {
      creatorImage: imageSharp(original: { src: { regex: "/creator/" } }) {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `);

  return (
    <>
      <SEO
        title={title}
        type="article"
        urlPath={path}
        desc={desc}
        schema={schema}
      ></SEO>
      <Container>
        <PageTitle>คอร์สทั้งหมด</PageTitle>
        <Section>
          <SectionHeader
            title="คอร์สอบรมออนไลน์ล่าสุด"
            buttonText="ดูทั้งหมด"
            to="/classroom-courses"
            Icon={FaGlobe}
          ></SectionHeader>
          <CourseList>
            {classroomCourses.map(({ node }) => (
              <ClassroomCourseItem
                key={node.slug}
                creatorImage={creatorImage}
                course={node}
              ></ClassroomCourseItem>
            ))}
          </CourseList>
        </Section>
        <Section>
          <SectionHeader
            title="คอร์สวิดีโอออนไลน์ล่าสุด"
            buttonText="ดูทั้งหมด"
            to="/online-courses"
            Icon={FaVideo}
          ></SectionHeader>
          <CourseList>
            {onlineCourses.map(({ node }) => (
              <OnlineCourseItem
                key={node.slug}
                creatorImage={creatorImage}
                course={node}
              ></OnlineCourseItem>
            ))}
          </CourseList>
        </Section>
        {/* <Section>
          <SectionHeader
            title="คอร์สอบรมองค์กรล่าสุด"
            buttonText="ดูทั้งหมด"
            to="/enterprise-courses"
            Icon={FaUsers}
          ></SectionHeader>
          <CourseList>
            {enterpriseCourses.map(({ node }) => (
              <EnterpriseCourseItem
                key={node.slug}
                creatorImage={creatorImage}
                course={node}
              ></EnterpriseCourseItem>
            ))}
          </CourseList>
        </Section> */}
      </Container>
    </>
  );
};

export default Courses;
