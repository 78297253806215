import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacers.large};
`

const Title = styled.h2`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.large};
    line-height: 1.5;
  `}
`

const BrowseButton = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.white};
    background-color: ${theme.colors.main.secondary};
    margin-left: auto;
    padding: ${theme.spacers.xxsmall} ${theme.spacers.normal};
    font-size: ${theme.fontSizes.normal};
    border-radius: 2rem;
    line-height: 1.5;
    cursor: pointer;
  `}
`

const IconWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin-right: ${theme.spacers.normal};
    font-size: ${theme.fontSizes.large};

    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      width: ${theme.fontSizes.large};
      height: 5px;
      border-radius: ${theme.round.large};
      background-color: ${theme.colors.main.primary};
    }
  `}
`

function SectionHeader({ title, buttonText, to, Icon }) {
  return (
    <Header>
      <IconWrapper>
        <Icon></Icon>
      </IconWrapper>
      <Title>{title}</Title>
      <BrowseButton to={to}>{buttonText}</BrowseButton>
    </Header>
  )
}

export default SectionHeader
