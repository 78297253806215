import styled, { css } from 'styled-components'

const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: ${theme.spacers.large};
  `}
`

export default Container
