import React from 'react';
import { graphql } from 'gatsby';
import Courses from '../components/courses/Courses';

const CoursesTemplate = ({
  data: { onlineCourses, classroomCourses, enterpriseCourses },
}) => {
  return (
    <Courses
      onlineCourses={onlineCourses.edges}
      classroomCourses={classroomCourses.edges}
      enterpriseCourses={enterpriseCourses.edges}
    ></Courses>
  );
};

export default CoursesTemplate;

export const pageQuery = graphql`
  query CoursesQuery {
    onlineCourses: allCoursesYaml(
      filter: { status: { eq: "public" }, type: { eq: "online" } }
      sort: { fields: createdAt, order: DESC }
      limit: 6
    ) {
      edges {
        node {
          status
          slug
          title
          excerpt
          price
          promotion
          meta {
            videos
            units
            hours
            level
          }
          coverImage: image {
            childImageSharp {
              fixed(width: 730, height: 415, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          mobileImage: image {
            childImageSharp {
              fluid(maxWidth: 450, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktopImage: image {
            childImageSharp {
              fixed(width: 150, height: 85, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
    classroomCourses: allCoursesYaml(
      filter: { status: { eq: "public" }, type: { eq: "classroom" } }
      sort: { fields: createdAt, order: DESC }
      limit: 6
    ) {
      edges {
        node {
          status
          slug
          title
          excerpt
          livePrice
          videoPrice
          liveTime
          coverImage: image {
            childImageSharp {
              fixed(width: 730, height: 415, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          mobileImage: image {
            childImageSharp {
              fluid(maxWidth: 450, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktopImage: image {
            childImageSharp {
              fixed(width: 150, height: 85, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
    enterpriseCourses: allCoursesYaml(
      filter: { status: { eq: "public" }, type: { eq: "enterprise" } }
      sort: { fields: createdAt, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          status
          slug
          title
          excerpt
          price
          promotion
          meta {
            videos
            units
            hours
            level
          }
          coverImage: image {
            childImageSharp {
              fixed(width: 730, height: 415, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          mobileImage: image {
            childImageSharp {
              fluid(maxWidth: 450, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          desktopImage: image {
            childImageSharp {
              fixed(width: 150, height: 85, quality: 50, webpQuality: 50) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
